//Utilities

@import 'mixins/transparency';
@import 'mixins/vendor-prefixes';
@import 'mixins/chartist';
@import 'mixins/sidebar-color';

// Placeholder text
@mixin material-placeholder() {
  &::-moz-placeholder {
    @content;
  } // Firefox
  &:-ms-input-placeholder {
    @content;
  } // Internet Explorer 10+
  &::-webkit-input-placeholder {
    @content;
  } // Safari and Chrome
}

// variations(unquote(""), background-color, #FFF);
@mixin variations($component, $selector-suffix, $mdb-param-1, $color-default) {
  @include generic-variations(
    $component,
    $selector-suffix,
    $color-default,
    'variations-content',
    $mdb-param-1
  );
}

@mixin variations-content($args) {
  //@debug "#{map-get($args, mixin-name)}{ #{map-get($args, material-param-1)}: #{map-get($args, variation-color)}; }";
  //@debug "#{inspect($args)}";
  //@error "break here";
  #{map-get($args, material-param-1)}: map-get($args, variation-color);
}

@mixin background-variations($component, $selector-suffix, $color-default) {
  @include generic-variations(
    $component,
    $selector-suffix,
    $color-default,
    'background-variations-content',
    null
  );
}

@mixin background-variations-content($args) {
  background-color: map-get($args, variation-color);
  @if (map-get($args, variation-color) == $mdb-btn-background-color) {
    color: $mdb-text-color-primary;
  } @else {
    color: map-get($args, variation-color-text);
  }
}

//@mixin text-variations($component, $selector-suffix, $color-default) {
//  @include generic-variations($component, $selector-suffix, $color-default, "text-variations-content", null);
//}
//
//@mixin text-variations-content($args) {
//  color: map-get($args, variation-color);
//}

@mixin button-variations($component, $selector-suffix, $color-default) {
  @include generic-variations(
    $component,
    $selector-suffix,
    $color-default,
    'button-variations-content',
    4%
  );
}

@mixin button-variations-content($args) {
  //@debug "#{inspect($args)}";
  $variation-color: map-get($args, variation-color);
  $mdb-param-1: map-get($args, material-param-1);
  background-color: contrast-color(
    $variation-color,
    darken($variation-color, $mdb-param-1),
    lighten($variation-color, $mdb-param-1)
  );
}

// navbar-variations(" label input[type=checkbox]:checked + .toggle:active:after", $brand-primary
@mixin navbar-variations($component, $selector-suffix, $color-default) {
  @include generic-variations(
    $component,
    $selector-suffix,
    $color-default,
    'navbar-variations-content',
    null
  );
}

@mixin navbar-variations-content($args) {
  $variation-color: map-get($args, variation-color);
  $variation-color-text: map-get($args, variation-color-text);

  background-color: $variation-color;
  color: $variation-color-text;
  // deeply defined to override welljumbo class without !impotant need
  .navbar-form .form-group input.form-control,
  .navbar-form input.form-control {
    @include material-placeholder {
      color: $variation-color-text;
    }
  }
  .dropdown-menu {
    border-radius: $border-radius-base !important;
    li > a {
      &:hover,
      &:focus {
        color: $white-color;
        background-color: $variation-color;
        @include shadow-big-color($variation-color);
      }
    }

    .active > a {
      &:hover,
      &:focus {
        color: $variation-color-text;
      }
      background-color: $variation-color;
      color: $variation-color-text;
      @include shadow-big-color($variation-color);
    }
  }
}

// alert-variations("", $brand-primary)
@mixin alert-variations($component, $selector-suffix, $color-default) {
  @include generic-variations(
    $component,
    $selector-suffix,
    $color-default,
    'alert-variations-content',
    null
  );
}

@mixin alert-variations-content($args) {
  $variation-color: map-get($args, variation-color);
  $variation-color-text: map-get($args, variation-color-text);

  @if $variation-color == $brand-info {
    background-color: $variation-color;
  } @else {
    background-color: lighten($variation-color, 5%);
  }
  color: $variation-color-text;
  border-radius: $border-radius-base;

  @include shadow-big-color($variation-color);

  a,
  .alert-link {
    color: $variation-color-text;
  }
}

// interpolation of mixin-name is not allowed evidently, so we statically include based on the mixin-name given
@mixin call-variations-content-mixin($args) {
  $mixin-name: map-get($args, mixin-name);
  @if $mixin-name == variations-content {
    @include variations-content($args);
  } @else if $mixin-name == background-variations-content {
    @include background-variations-content($args);
  } @else if $mixin-name == text-variations-content {
    @include text-variations-content($args);
  } @else if $mixin-name == button-variations-content {
    @include button-variations-content($args);
  } @else if $mixin-name == bg-color-variations-content {
    @include bg-color-variations-content($args);
  } @else if $mixin-name == bg-box-shadow-variations-content {
    @include bg-box-shadow-variations-content($args);
  } @else if $mixin-name == bg-img-variations-content {
    @include bg-img-variations-content($args);
  } @else if $mixin-name == navbar-variations-content {
    @include navbar-variations-content($args);
  } @else if $mixin-name == alert-variations-content {
    @include alert-variations-content($args);
  } @else {
    @error "Unknown mixin: #{$mixin-name}";
  }
}

//
// To use this mixin you should pass a function as final parameter to define
// the style. In that definition you can use the following variables to define it.
//
// $variation-color-name ---> "red", "green", "indigo" ...
// $variation-color-full-name ---> "red", "green-50", "indigo-400" ...
// $variation-color ---> #f44336, #e8f5e9, #5c6bc0 ...
// $variation-color-text ---> rgba(255,255,255,0.84), rgba(0,0,0,0.84), rgba(255,255,255,0.84) ...
//

@mixin generic-variations(
  $component,
  $selector-suffix,
  $color-default,
  $mixin-name,
  $mdb-param-1
) {
  //setup map to pass parameters (instead of the incredibly long-error-prone list for each and every @include)
  $args: (
    //extra: $selector-suffix,
    //default: $color-default,
    mixin-name: $mixin-name,
    material-param-1: $mdb-param-1
  );

  // bootstrap styles
  &#{$selector-suffix},
  &#{$component}-default#{$selector-suffix} {
    $args-extra: map-merge(
      $args,
      (
        variation-color: $white-color,
        variation-color-text: $gray,
      )
    );
    @include call-variations-content-mixin($args-extra);
  }
  &#{$component}-inverse#{$selector-suffix} {
    $args-inverse: map-merge(
      $args,
      (
        variation-color: #212121,
        variation-color-text: #fff,
      )
    );
    @include call-variations-content-mixin($args-inverse);
  }
  &#{$component}-primary#{$selector-suffix} {
    $args-primary: map-merge(
      $args,
      (
        variation-color: $brand-primary,
        variation-color-text: $mdb-text-color-light,
      )
    );
    @include call-variations-content-mixin($args-primary);
  }
  &#{$component}-success#{$selector-suffix} {
    $args-success: map-merge(
      $args,
      (
        variation-color: $brand-success,
        variation-color-text: $mdb-text-color-light,
      )
    );
    @include call-variations-content-mixin($args-success);
  }
  &#{$component}-info#{$selector-suffix} {
    $args-info: map-merge(
      $args,
      (
        variation-color: $brand-info,
        variation-color-text: $mdb-text-color-light,
      )
    );
    @include call-variations-content-mixin($args-info);
  }
  &#{$component}-warning#{$selector-suffix} {
    $args-warning: map-merge(
      $args,
      (
        variation-color: $brand-warning,
        variation-color-text: $mdb-text-color-light,
      )
    );
    @include call-variations-content-mixin($args-warning);
  }
  &#{$component}-danger#{$selector-suffix} {
    $args-danger: map-merge(
      $args,
      (
        variation-color: $brand-danger,
        variation-color-text: $mdb-text-color-light,
      )
    );
    @include call-variations-content-mixin($args-danger);
  }

  &#{$component}-rose#{$selector-suffix} {
    $args-rose: map-merge(
      $args,
      (
        variation-color: $brand-rose,
        variation-color-text: $mdb-text-color-light,
      )
    );
    @include call-variations-content-mixin($args-rose);
  }
}

@mixin transition($time, $type) {
  -webkit-transition: all $time $type;
  -moz-transition: all $time $type;
  -o-transition: all $time $type;
  -ms-transition: all $time $type;
  transition: all $time $type;
}

@mixin transform-scale($value) {
  -webkit-transform: scale($value);
  -moz-transform: scale($value);
  -o-transform: scale($value);
  -ms-transform: scale($value);
  transform: scale($value);
}

@mixin transform-translate-x($value) {
  -webkit-transform: translate3d($value, 0, 0);
  -moz-transform: translate3d($value, 0, 0);
  -o-transform: translate3d($value, 0, 0);
  -ms-transform: translate3d($value, 0, 0);
  transform: translate3d($value, 0, 0);
}

@mixin transform-translate-y($value) {
  -webkit-transform: translate3d(0, $value, 0);
  -moz-transform: translate3d(0, $value, 0);
  -o-transform: translate3d(0, $value, 0);
  -ms-transform: translate3d(0, $value, 0);
  transform: translate3d(0, $value, 0);
}

@mixin transform-origin($coordinates) {
  -webkit-transform-origin: $coordinates;
  -moz-transform-origin: $coordinates;
  -o-transform-origin: $coordinates;
  -ms-transform-origin: $coordinates;
  transform-origin: $coordinates;
}

@mixin black-filter() {
  background: rgba(0, 0, 0, 0.55);
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  z-index: 0;
  left: 0;
  top: 0;
}

@mixin radial-gradient($extern-color, $center-color) {
  background: $extern-color;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    $center-color 0%,
    $extern-color 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, $center-color),
    color-stop(100%, $extern-color)
  ); /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    $center-color 0%,
    $extern-color 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    $center-color 0%,
    $extern-color 100%
  ); /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    $center-color 0%,
    $extern-color 100%
  ); /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    $center-color 0%,
    $extern-color 100%
  ); /* W3C */
  background-size: 550% 450%;
}

@mixin tag-color($color) {
  background-color: $color;
  color: $white-color;
}

@mixin create-colored-tags() {
  &.tag-primary {
    @include tag-color($brand-primary);
  }
  &.tag-info {
    @include tag-color($brand-info);
  }
  &.tag-success {
    @include tag-color($brand-success);
  }
  &.tag-warning {
    @include tag-color($brand-warning);
  }
  &.tag-danger {
    @include tag-color($brand-danger);
  }
  &.tag-rose {
    @include tag-color($brand-rose);
  }
}

@mixin rotate-180() {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

@mixin linear-gradient($color1, $color2) {
  background: $color1; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    60deg,
    $color1,
    $color2
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    60deg,
    $color1,
    $color2
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    60deg,
    $color1,
    $color2
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(60deg, $color1, $color2); /* Standard syntax */
}

@mixin set-background-color-button($color) {
  li.active > a {
    background-color: $color;
    @include shadow-big-color($color);
  }
}

// Mixins for buttons

@mixin btn-styles($btn-color) {
  // remove this line if you want black shadows
  @include shadow-2dp-color($btn-color);

  &,
  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &:active:hover,
  &.active:focus,
  &.active:hover,
  .open > &.dropdown-toggle,
  .open > &.dropdown-toggle:focus,
  .open > &.dropdown-toggle:hover {
    background-color: $btn-color;
    color: $white-color;
  }

  &:focus,
  &:active,
  &:hover {
    // remove this line if you want black shadows
    @include button-shadow-color($btn-color);
  }

  &.disabled,
  &:disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      box-shadow: none;
    }
  }

  &.btn-simple {
    background-color: transparent;
    color: $btn-color;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: $btn-color;
    }
  }
}

// for social buttons
@mixin social-buttons-color($color) {
  background-color: $color;
  color: #fff;
  @include shadow-2dp-color($color);

  &:focus,
  &:active,
  &:hover {
    background-color: $color;
    color: #fff;
    @include button-shadow-color($color);
  }

  &.btn-simple {
    color: $color;
    background-color: transparent;
    box-shadow: none;
  }
}

@mixin set-wizard-color($color) {
  .moving-tab {
    background-color: $color;
    @include shadow-big-color($color);
  }

  .picture {
    &:hover {
      border-color: $color;
    }
  }

  .choice {
    &:hover,
    &.active {
      .icon {
        border-color: $color;
        color: $color;
      }
    }
  }

  .form-group {
    .form-control {
      background-image: linear-gradient($color, $color),
        linear-gradient($mdb-input-underline-color, $mdb-input-underline-color);
    }
  }

  .checkbox input[type='checkbox']:checked + .checkbox-material {
    .check {
      background-color: $color;
    }
  }

  .radio input[type='radio']:checked ~ .check {
    background-color: $color;
  }

  .radio input[type='radio']:checked ~ .circle {
    border-color: $color;
  }
}

@mixin lock-page-input-color($color) {
  &.lock-page {
    .form-group {
      .form-control {
        background-image: linear-gradient($color, $color),
          linear-gradient(
            $mdb-input-underline-color,
            $mdb-input-underline-color
          );
      }
    }
  }
}

@mixin alert-icon-color($color) {
  i {
    color: $color;
  }
}

@mixin timeline-badge-color($color) {
  background-color: $color;
  @include shadow-big-color($color);
}
